import * as React from "react";
import '../styles/App.scss';

const viberIcon = require('./assets/img/viber.svg')
const telegramIcon = require('./assets/img/telegram.svg')

class App extends React.PureComponent {
    render() {
        return (
            <div className="main-container">
                <div className="header-container">
                    <span>050 242 26 54</span>
                    <a href="viber://chat?number=%2B380502422654">
                        <img src={viberIcon} />
                    </a>
                    <a href="tg://resolve?domain=maysternyachochliv">
                        <img src={telegramIcon} />
                    </a>
                    <span>Пишите нам!</span>
                </div>
                <div className="content-container">
                <iframe className="frame" src="https://print.caseshop.com.ua/b0f87320-aa1f-42d1-b63f-b7f6e49a5741" width="100%" height="100%">
                </iframe>
                </div>
            </div>
        );
    }
}

export default App;